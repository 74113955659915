/**
 * Type: ページ
 * What: 料金ページ
 */
import React from 'react';
import { scroller } from 'react-scroll';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';
import { Button } from '../../components/Btn';

const pageTitle = '料金';
const pageDescription =
  'BOSS E・ZO FUKUOKAの各アトラクションの料金と、駐車場の料金についてご案内いたします。チケットは専用サイトにて事前予約と当日購入が可能です。';
const pageSlug = 'ticket';

const scrollToTarget = (e, elem, offset) => {
  e.preventDefault();
  let $offset = offset || -160;
  if (window.matchMedia('screen and (max-width: 768px)').matches) {
    $offset = offset || -110;
  }
  scroller.scrollTo(elem, {
    duration: 1200,
    delay: 0,
    offset: $offset,
    smooth: 'easeOutQuint',
  });
};

// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />

    <section className="section">
      <div className="container">
        <div className="btn-wrap price-btn mb-6">
          <Button href="/ticket/how" className="brand mr-2">
            チケット
            <br className="sp" />
            購入方法
          </Button>
          <Button href="/ticket/price" className="brand tab-choice ml-2">
            料金
          </Button>
        </div>

        <div className="section-link">
          <ul>
            <li>
              <a
                href="#sp-rf"
                onClick={(e) => scrollToTarget(e, 'sp-rf')}
                onKeyDown={(e) => scrollToTarget(e, 'sp-rf')}
              >
                <span>絶景3兄弟 SMBC日興証券</span>
              </a>
            </li>
            <li>
              <a
                href="#sp-dreaming"
                onClick={(e) => scrollToTarget(e, 'sp-dreaming')}
                onKeyDown={(e) => scrollToTarget(e, 'sp-dreaming')}
              >
                <span>Sanrio characters Dream!ng Park</span>
              </a>
            </li>
            <li>
              <a
                href="#sp-67"
                onClick={(e) => scrollToTarget(e, 'sp-67')}
                onKeyDown={(e) => scrollToTarget(e, 'sp-67')}
              >
                <span>V-World AREA クレディ・アグリコル</span>
              </a>
            </li>
            <li>
              <a
                href="#sp-5"
                onClick={(e) => scrollToTarget(e, 'sp-5')}
                onKeyDown={(e) => scrollToTarget(e, 'sp-5')}
              >
                <span>チームラボフォレスト 福岡 - SBI証券</span>
              </a>
            </li>
            <li>
              <a
                href="#sp-4"
                onClick={(e) => scrollToTarget(e, 'sp-4')}
                onKeyDown={(e) => scrollToTarget(e, 'sp-4')}
              >
                <span>
                  王貞治ベースボールミュージアム
                  <br />
                  Supported by DREAM ORDER
                </span>
              </a>
            </li>
            <li>
              <a
                href="#parking"
                onClick={(e) => scrollToTarget(e, 'parking')}
                onKeyDown={(e) => scrollToTarget(e, 'parking')}
              >
                <span>駐車場</span>
              </a>
            </li>
          </ul>
        </div>

        <h3 className="title is-3">料金表</h3>

        <div className="price-floor mb60 tac">
          <p className="text is-3 is-3-sp">
            日程により料金が異なります。料金体系や販売状況は以下にてご確認ください。
          </p>
          <p className="ltext is-3 is-3-sp mt10">
            <a className="tx-link" href="https://e-zofukuoka.com/ticket/">
              https://e-zofukuoka.com/ticket/
            </a>
          </p>
        </div>

        <div className="table-base">
          <h5 className="title is-6 price-floor" id="sp-rf">
            RF
          </h5>
          <table className="price-table">
            <tbody>
              <tr>
                <th colSpan="2">
                  <a href="/zekkei-brothers/subezo/" className="tx-link">
                    絶景3兄弟 すべZO SMBC日興証券
                  </a>
                </th>
              </tr>
              <tr>
                <td>ピーク</td>
                <td>1,500円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>1,200円</td>
              </tr>
              <tr>
                <th colSpan="2">
                  <a href="/zekkei-brothers/tsurizo/" className="tx-link">
                    絶景3兄弟 つりZO SMBC日興証券
                  </a>
                </th>
              </tr>
              <tr>
                <td>ピーク</td>
                <td>2,500円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>1,800円</td>
              </tr>
              <tr>
                <th colSpan="2">
                  <a href="/zekkei-brothers/nobozo/" className="tx-link">
                    絶景3兄弟 のぼZO クライミング SMBC日興証券
                  </a>
                </th>
              </tr>
              <tr>
                <td>ピーク</td>
                <td>1,000円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>900円</td>
              </tr>
              <tr>
                <th colSpan="2">
                  <a href="/zekkei-brothers/nobozo/" className="tx-link">
                    絶景3兄弟 のぼZO ボルダリング SMBC日興証券
                  </a>
                </th>
              </tr>
              <tr>
                <td>ピーク</td>
                <td>600円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>500円</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-base" id="sp-dreaming">
          <h5 className="title is-6 price-floor">7階</h5>
          <table className="price-table">
            <tbody>
              <tr>
                <th colSpan="2">
                  <a href="/dreamingpark/" className="tx-link">
                    Sanrio characters Dream!ng Park
                  </a>
                </th>
              </tr>
              <tr>
                <td colSpan="2">
                  <p>入場料金：無料</p>
                  <p className="mt-1">
                    コンテンツプレイ料金：コンテンツ共通券：500円／1枚
                  </p>
                  <p className="mt-3 tal">
                    ※チケット1枚につき、5つのコンテンツから1つをお選びいただけます。
                  </p>
                  <p className="mt-1 tal">
                    ※「Dream!ng SMILES AR
                    SNAP」は1組1枚、その他のコンテンツは、お1人様につき1枚のチケットが必要です。
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-base" id="sp-67">
          <h5 className="title is-6 price-floor">6階</h5>
          <table className="price-table">
            <tbody>
              <tr>
                <th colSpan="3">
                  <a href="/v-world/" className="tx-link">
                    V-World AREA クレディ・アグリコル
                  </a>
                </th>
              </tr>

              <tr className="sub">
                <th colSpan="3">2時間チケット</th>
              </tr>
              <tr>
                <td rowSpan="2">23歳以上</td>
                <td>ピーク</td>
                <td>3,000円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>2,800円</td>
              </tr>
              <tr>
                <td rowSpan="2">13~22歳</td>
                <td>ピーク</td>
                <td>2,300円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>2,200円</td>
              </tr>
              <tr>
                <td rowSpan="2">12歳以下</td>
                <td>ピーク</td>
                <td>
                  1,800円 ※<sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>
                  1,700円 ※<sup>1</sup>
                </td>
              </tr>

              <tr className="sub">
                <th colSpan="3">30分チケット</th>
              </tr>
              <tr>
                <td rowSpan="2">23歳以上</td>
                <td>ピーク</td>
                <td>1,200円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>1,000円</td>
              </tr>
              <tr>
                <td rowSpan="2">13~22歳</td>
                <td>ピーク</td>
                <td>950円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>800円</td>
              </tr>
              <tr>
                <td rowSpan="2">12歳以下</td>
                <td>ピーク</td>
                <td>
                  600円 ※<sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>
                  500円 ※<sup>1</sup>
                </td>
              </tr>

              {/* <tr class="sub"><th colspan="3">V-World AREA クレディ・アグリコル 2時間チケット<br />＋みずほPayPayドームバンジーVR 1回チケット</th></tr>
              <tr>
                <td>23歳以上</td>
                <td>ピーク</td>
                <td>3,300円</td>
              </tr>
              <tr>
                <td>13~22歳</td>
                <td>ピーク</td>
                <td>2,700円</td>
              </tr>
              <tr>
                <td>12歳以下</td>
                <td>ピーク</td>
                <td>2,000円 ※<sup>2</sup></td>
              </tr>
              <tr class="sub"><th colspan="3">V-World AREA クレディ・アグリコル 30分チケット<br />＋みずほPayPayドームバンジーVR 1回チケット</th></tr>
              <tr>
                <td>23歳以上</td>
                <td>ピーク</td>
                <td>1,700円</td>
              </tr>
              <tr>
                <td>13~22歳</td>
                <td>ピーク</td>
                <td>1,450円</td>
              </tr>
              <tr>
                <td>12歳以下</td>
                <td>ピーク</td>
                <td>1,100円 ※<sup>2</sup></td>
              </tr> */}
            </tbody>
          </table>
        </div>

        <div className="table-base" id="sp-5">
          <h5 className="title is-6 price-floor">5階</h5>
          <table className="price-table">
            <tbody>
              <tr>
                <th colSpan="3">
                  <a href="/teamlabforest/" className="tx-link">
                    チームラボフォレスト 福岡 - SBI証券
                  </a>
                </th>
              </tr>

              <tr>
                <td rowSpan="2">
                  大人
                  <br />
                  16歳以上
                </td>
                <td>ピーク</td>
                <td>2,700円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>2,400円</td>
              </tr>

              <tr>
                <td rowSpan="2">高校生・大学生・専門学生</td>
                <td>ピーク</td>
                <td>
                  2,200円 ※<sup>4</sup>
                </td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>
                  2,000円 ※<sup>4</sup>
                </td>
              </tr>

              <tr>
                <td rowSpan="2">
                  小人
                  <br />
                  4歳～15歳
                </td>
                <td>ピーク</td>
                <td>
                  1,200円 ※<sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>
                  1,000円 ※<sup>1</sup>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-base" id="sp-4">
          <h5 className="title is-6 price-floor">4階</h5>
          <table className="price-table">
            <tbody>
              <tr>
                <th colSpan="3">
                  <a href="/obm/" className="tx-link">
                    王貞治ベースボールミュージアム
                    <br />
                    Supported by DREAM ORDER
                  </a>
                </th>
              </tr>
              <tr>
                <td>16歳以上</td>
                <td>ピーク／レギュラー</td>
                <td>1,800円</td>
              </tr>
              <tr>
                <td>15歳以下</td>
                <td>ピーク／レギュラー</td>
                <td>
                  900円 ※<sup>3</sup>
                </td>
              </tr>

              <tr className="sub">
                <th colSpan="3">
                  <a href="/obm/89park/" className="tx-link">
                    89パーク
                  </a>
                </th>
              </tr>
              <tr>
                <td rowSpan="2">16歳以上</td>
                <td>ピーク</td>
                <td>1,200円</td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>1,000円</td>
              </tr>
              <tr>
                <td rowSpan="2">15歳以下</td>
                <td>ピーク</td>
                <td>
                  600円 ※<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td>レギュラー</td>
                <td>
                  500円 ※<sup>3</sup>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="price-floor">
          <p className="list-mark">
            ※屋上アトラクションには各種利用制限があります。
          </p>
          <p className="list-mark">
            ※<sup>1</sup> 3歳以下無料（要保護者同伴）
          </p>
          <p className="list-mark fwb attention">
            ※<sup>2</sup>{' '}
            3歳以下無料、12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
          </p>
          <p className="list-mark">
            ※<sup>3</sup>{' '}
            6歳以下無料、12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
          </p>
          <p className="list-mark">
            ※<sup>3</sup> 小学1年生より有料
          </p>
          <p className="list-mark">
            ※<sup>4</sup>{' '}
            学生料金の場合、アトラクション受付にて学生証の確認を致します
          </p>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="price-table">
          <h3 className="title is-3">障がい者割引について</h3>
          <p className="mb-1">
            アトラクションによって障がい者割引の適用がございます。
          </p>
          <p className="mb-1">
            障がい者割引チケットをご購入のお客様は該当のチケットを購入後、各アトラクション入場口で障がい者手帳をご提示ください。
          </p>
          <p className="mb-1">
            ※身体障害者手帳、療育手帳、精神障害者手帳保健福祉手帳の3つが対象です。
          </p>
          <p className="mb-1">
            ※交付を受けている方本人のみ割引適用となります。
          </p>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h3 className="title is-3" id="parking">
          駐車場　料金
        </h3>
        <div className="btn-wrap">
          <a
            href="https://www.softbankhawks.co.jp/stadium/parking/"
            className="btn-regular brand"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>詳しくはこちらををご覧ください</span>
            <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
              <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
              <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
            </svg>
          </a>
        </div>
        {/* <div className="tac mb-6">
          <Image filename="bnr-parking.jpg" />
        </div>
        <div className="table-base price-table">
          <h4 className="title is-6">みずほPayPayドーム<br className="sp" />野球開催日・コンサート開催日</h4>
          <p>普通車：1日 3,000円</p>
          <p>大型車：1日 5,000円（事前予約制）</p>
          <p className="mt-2">ご利用金額に応じてご返金いたします</p>
          <table className="pc mt-5">
            <tbody>
              <tr>
                <th className="parking-ttl"> </th>
                <th className="parking-column">ご利用金額（税込）</th>
                <th className="parking-column">ご返金額</th>
              </tr>
              <tr>
                <td rowSpan="3" className="parking-ttl">全日</td>
                <td className="parking-column">税込<span className="text is-2 pr-1 pl-1">2,000</span>円以上</td>
                <td className="parking-column"><span className="text is-2 pr-1">1,000</span>円ご返金</td>
              </tr>
              <tr>
                <td className="parking-column">税込<span className="text is-2 pr-1 pl-1">4,000</span>円以上</td>
                <td className="parking-column"><span className="text is-2 pr-1">2,000</span>円ご返金</td>
              </tr>
              <tr>
                <td className="parking-column">税込<span className="text is-2 pr-1 pl-1">6,000</span>円以上</td>
                <td className="parking-column"><span className="text is-2 pr-1">3,000</span>円ご返金</td>
              </tr>
            </tbody>
          </table>
          <table className="price-table sp">
            <tbody>
              <tr>
                <th colSpan="2">全日</th>
              </tr>
              <tr className="sub">
                <td>ご利用金額（税込）</td>
                <td>ご返金額</td>
              </tr>
              <tr>
                <td>税込<span className="text is-2 pr-1 pl-1">2,000</span>円以上</td>
                <td><span className="text is-2 pr-1">1,000</span>円ご返金</td>
              </tr>
              <tr>
                <td>税込<span className="text is-2 pr-1 pl-1">4,000</span>円以上</td>
                <td><span className="text is-2 pr-1">2,000</span>円ご返金</td>
              </tr>
              <tr>
                <td>税込<span className="text is-2 pr-1 pl-1">6,000</span>円以上</td>
                <td><span className="text is-2 pr-1">3,000</span>円ご返金</td>
              </tr>
            </tbody>
          </table>
          <p className="list-mark">※ご返金は券面記載の当日のみ有効です</p>
        </div>
        <div className="table-base price-table">
          <h4 className="title is-5 mt-6">ドームイベント非開催日</h4>
          <p>最初の1時間：300円以降30分毎150円ずつ加算</p>
          <p className="mt-2">ご利用に応じて一定時間駐車場料金が無料になります</p>
          <table className="pc mt-5">
            <tbody>
              <tr>
                <th className="parking-ttl"> </th>
                <th className="parking-column">ご利用金額（税込）</th>
                <th className="parking-column">無料時間</th>
              </tr>
              <tr>
                <td className="parking-ttl">平日</td>
                <td rowSpan="2" className="parking-column">税込<span className="text is-2 pr-1 pl-1">1</span>円以上ご利用</td>
                <td className="parking-column">最大<span className="text is-2 pr-1 pl-1">5</span>時間無料</td>
              </tr>
              <tr>
                <td className="parking-ttl">土日祝</td>
                <td className="parking-column">最大<span className="text is-2 pr-1 pl-1">3</span>時間無料</td>
              </tr>
            </tbody>
          </table>
          <table className="price-table sp">
            <tbody>
              <tr>
                <th colSpan="2">平日</th>
              </tr>
              <tr className="sub">
                <td>ご利用金額（税込）</td>
                <td>無料時間</td>
              </tr>
              <tr>
                <td>税込<span className="text is-2 pr-1 pl-1">1</span>円以上ご利用</td>
                <td>最大<span className="text is-2 pr-1 pl-1">5</span>時間無料</td>
              </tr>
            </tbody>
          </table>
          <table className="price-table sp">
            <tbody>
              <tr>
                <th colSpan="2">土日祝</th>
              </tr>
              <tr className="sub">
                <td>ご利用金額（税込）</td>
                <td>無料時間</td>
              </tr>
              <tr>
                <td>税込<span className="text is-2 pr-1 pl-1">1</span>円以上ご利用</td>
                <td>最大<span className="text is-2 pr-1 pl-1">3</span>時間無料</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="inner-slim mt-6">
          <h3 className="title is-5">＜試合日・コンサート開催日＞</h3>
          <p>ドーム駐車場入庫時に「入金証明書」をお渡しいたします。<br />
          入金証明書とBOSS E・ZO FUKUOKA1F・4F～RFのご利用チケット、または3F飲食店舗のレシートを3F informationにてご提示ください。</p>
          <div className="tac mt-5">
            <Image src="img-deposit-price.jpg" />
          </div>
          <p className="list-mark mb-0 mt-5">※入金証明書はスーパーボックスご利用のお客様以外の方へ入庫時にお渡しします。</p>
        </div>
       */}
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
